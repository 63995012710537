import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/views/homePage/index.vue'),
    meta: {
      title: '首页'
    }
  },
  {
    path: '/personalCenter',
    component: () => import('@/views/personalCenter/index.vue'),
    meta: {
      title: '个人中心'
    }
  }
]

const router = new VueRouter({
  routes
})

export default router
